import React , {useState} from "react";
import Upload from "./Upload";
import "./Buchung.css";
import NumericInput from 'react-numeric-input';
import CenteredContainer from "./CenteredContainer";
import Checkbox from "./Checkbox";
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//const keydown = (event) => {
//    console.log(event.key);
//    return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space';
//    }

const restrictNumericInput = (event) => {
    event.target.value = event.target.value.replace(/[^0-9]/g, "")
    event.target.value = event.target.value.slice(0,3);
}
const Sumbit = () => {
    const toastId = React.useRef(null);
    const [path, setPath] = useState("");
    const [date, setDate] = useState("");
    const notify = () => toastId.current = toast("Hello", { autoClose: false });
    const dismissAll = () =>  toast.dismiss();
    dismissAll();
    const locations = ['große Aula', 'Mensa','Stadthalle','Turnhalle'];
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [form, setForm] = useState({
        date: '',
        start: '',
        end: '',
        occasion: '',
        additionalPeople: '',
        chairs: 0,
        custom: '',
        teacher: '',
        headsets: 0,
        laptopUser: '',
        media: false,
        mics: 0,
        ownLaptop: '',
        presence: '',
        pult: false,
        customLocation: '',
    });
    
    const setState = (ev,name) => {
        if (!name) {
            name = ev.target.name;
        }
        console.log(ev);
        if (ev&&ev.target) {
            if (ev.target.type === "checkbox") {
                ev = ev.target.checked;
            } else {
                ev = ev.target.value;
            }
        }
        if (name=="occasion") {
            setPath(ev.replace(/[/\\?%*:|"<>]/g, '-'));
        }
        if (name=="date") {
            setDate(ev.split("-")[0]);
        }
        let newState = form;
        newState[name] = ev;
        console.log(newState);
        setForm(newState);
    }

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleLocationChange = (event) => {
        const location = event.target.value;
        setSelectedLocations(prevLocations =>
            prevLocations.includes(location)
                ? prevLocations.filter(loc => loc !== location)
                : [...prevLocations, location]
        );
    };

    const sendSubmit = (data) => {
        return new Promise(function(resolve, reject) {
        console.log(form);
        axios.post('/api/submit', data)
            .then(res => {
                console.log(res);
                if (res.data.success) {
                    resolve(res.data.message);
                }
                reject(res.data.message);
            })
            .catch(err => {
                console.log(err);
                reject(err.response.data.message);
            });
        });
    }

    const handleSubmit = () => {
        const data = {
            ...form,
            locations: selectedLocations,
        };
        //const resolveWithSomeData = new Promise(function(resolve, reject) {
        //    setTimeout(()=>{
        //        reject("awdaw");
        //    }, 500);
        //}
        //);
        //const submitPromise = new Promise(function(resolve, reject) {
        //    sendSubmit(data);
        toast.promise(
            sendSubmit(data),
            {
              pending: {
                render(){
                  return "Buchung in bearbeitung"
                },
              },
              success: {
                render({data}){
                  return data
                },
                //icon: "🟢",
              },
              error: {
                render({data}){
                  return data
                },
                //icon: "🔴",
              }
            }
        )
        console.log(data);
    }
  const uploadUpdate = (data)=>{
    console.log(data);
  }

  //https://codesandbox.io/p/sandbox/yvp79r4251?file=%2Fsrc%2Findex.js%3A8%2C36
  //let state = { checked: false }
  //const handleCheckboxChange = event => {
  //  this.setState({ checked: event.target.checked })
  //}
  return (
    <div>
        <ToastContainer autoClose={1000} style={{top: "5px"}}/>
        <h2 style={{textAlign:"center",padding:"20px",color:"var(--accent)"}}><b>Buchung der Schultechniker</b></h2>
        {
        //<header style={{padding:"2em 1em",margin:"15px"}} className="bg-primary jumbotron">
        //    <h2 style={{textAlign:"center"}}>Buchung der Schultechniker</h2>
        //</header>
        }
        
        <div className="inputs">
        <div>
            <h4>Wochentag/Datum</h4>
            <input type="date" onChange={setState} name="date" className="input-date"/>
        </div>
        <div>
            <h4>Uhrzeit</h4>
            <input type="time" onChange={setState} name="start" className="input-time"/>
            <div> - </div>
            <input type="time" onChange={setState} name="end" className="input-time"/>
        </div>
        <div className="input-text">
            <h4>Anlass</h4>
            <input type="text" onChange={setState} name="occasion"/>
        </div>
        <div>
            <h4>Ort</h4>
            <div className="input-location">
                {locations.map(location => (
                    <label style={{ backgroundColor: selectedLocations.includes(location) ? 'var(--accent)' : 'inherit'}}>
                        <input
                            type="checkbox"
                            style={{ display: 'none' }}
                            name="location"
                            value={location}
                            onChange={handleLocationChange}
                        />
                        {location}
                    </label>
                ))}
                <input type="text" name="customLocation" placeholder="Benutzerdefinierter Ort" onChange={handleChange} />
            </div>
        </div>
        <div>
            <h4>Anwesenheit ab</h4>
            <input style={{width:"100%"}} type="time" onChange={setState} name="time" className="input-time"/>
        </div>
        <div>
            <h4>Technischer Bedarf</h4>
            <div>
                <label htmlFor="pult" className="input-checkbox">
                <div>
                <input id="pult" type="checkbox" onChange={setState} name="pult"/>
                Stehpult u. Standmicro
                </div>
                </label>
                <label htmlFor="display" className="input-checkbox">
                <div>
                {
                //<Checkbox
                //  checked={this.state.checked}
                //  onChange={this.handleCheckboxChange}
                ///>
                }
                <input type="checkbox" id="display" onChange={setState} name="media"/>
                Beamer/Laptop/Leinwand
                </div>
                </label>
                <br/>
                <div className="input-number">
                Anzahl an Handmikros
                <span>
                {
                //<NumericInput onInput={restrictNumericInput} onChange={(e)=>{setState(e,"mics")}} name="mics" inputMode="numeric" min={0} max={999} value={0}/>
                }
                <input type="number" onInput={restrictNumericInput} onChange={(e)=>{setState(e,"mics")}} name="mics" inputMode="numeric" min={0} max={999}/>
                </span>
                </div>
                <div className="input-number">
                Anzahl an Headsets
                <span>
                <input type="number" onInput={restrictNumericInput} onChange={(e)=>{setState(e,"headsets")}} name="headsets" inputMode="numeric" min={0} max={999}/>
                </span>
                </div>
                <div className="input-number">
                Bestuhlung
                <span>
                <input type="number" onInput={restrictNumericInput} onChange={(e)=>{setState(e,"chairs")}} name="chairs" inputMode="numeric" min={0} max={999} step={10}/>
                </span>
                </div>
                <div className="custom">
                Sonstiges:
                <input style={{marginLeft:"5px",width:"100%"}} type="text" onChange={setState} name="custom" placeholder="Keine Anmerkung" />
                </div>
            </div>
        </div>
        <div className="input-text">
            <h4>Lehrkraft (Hauptansprechpartner/-in)</h4>
            <input type="text" onChange={setState} name="teacher" />
        </div>
        <div className="input-text">
            <h4>ggf. weitere beteiligte Personen</h4>
            <input type="text" onChange={setState} name="additionalPeople" />
        </div>
        <div className="input-text">
            <h4>Präsentation oder andere Dateien</h4>
            <Upload updates={uploadUpdate} path={`${date} ${path}`} style={{width:"max-content"}} />
        </div>
        <div className="input-text">
            <h4>folgende Personen möchten ihren eigenen Laptop nutzen</h4>
            <input type="text" onChange={setState} name="ownLaptop" />
        </div>
        </div>
        <div style={{width:"100%",textAlign: "center",paddingBottom: "15px"}}>
        <button onClick={handleSubmit} style={{margin:"0 auto",backgroundColor: "var(--accent)"}} className="btn btn-primary">Senden</button>
        </div>
    </div>

  );
};
//path={`${date} ${path}`}
const Centered = () => {
    return (
        <CenteredContainer>
            <Sumbit />
        </CenteredContainer>
    )
}

export default Centered;

