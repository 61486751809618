import React, { useEffect,useState } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { keepTheme } from "./common/themes";

import AuthService from "./services/auth.service";

import Login from "./components/Login";
import Home from "./components/Home";
import Submit from "./components/Submit";
import Upload from "./components/Upload";
import Buchung from "./components/Buchung";
import ThemesToggle from "./components/ThemesToggle";

// import AuthVerify from "./common/AuthVerify";
import EventBus from "./common/EventBus";
import Planka from "./components/Planka";

const App = () => {
  const navigate = useNavigate()  ;
  const [className, setClassName] = useState("theme-light");
  useEffect(() => {
      keepTheme(setClassName);
  })
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  //const isLoggedIn = AuthService.isLoggedIn();
  useEffect(() => {
    setIsLoggedIn(AuthService.isLoggedIn());
    EventBus.on("logout", () => {
      logOut();
    });

    document.body.classList.add(className);
    return () => {
      EventBus.remove("logout");
      document.body.classList.remove(className);
    };
  }, [className]);

  const logOut = () => {
    AuthService.logout();
    setIsLoggedIn(false);
  };
  if (isLoggedIn&&window.location.pathname!="/buchung") {
    navigate("/buchung");
  }
  return (
    <div className={`${className} app`}>
      <nav className="navbar navbar-expand" style={{backgroundColor:"var(--dark-background)"}}>
        <Link to={isLoggedIn ?"/buchung":"/"} className="navbar-brand" style={{backgroundColor:"var(--dark-background)",color: "var(--accent)",fontSize:"20px",fontWeight:"bold"}}>
          Auftrag
        </Link>
        <ThemesToggle setClassName={setClassName} />
        <div className="navbar-nav">
          <li className="nav-item">
            {isLoggedIn ? (
                <Link to={"/"} onClick={logOut} className="nav-link">
                  <button type="button" className="btn btn-danger">Logout</button>
                </Link>
              ) : (
                <Link to={"/login"} className="nav-link">
                  <button type="button" className="btn btn-success">Login</button>
                </Link>
              )
            }
          </li>
        </div>
      </nav>

      <div style={{minWidth:"100px"}}>
        <Routes>
          {
          //<Route exact path={"/"} element={<Home />} />
          //<Route exact path="/submit" element={<Submit />} />
          //<Route exact path="/planka" element={<Planka />} />
          //<Route exact path="/upload" element={<Upload />} />
          }
          <Route exact path={"/"} element={<Login setIsLoggedIn={setIsLoggedIn} />} />
          <Route exact path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
          <Route exact path="/buchung" element={<Buchung />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
