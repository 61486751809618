function setTheme(themeName, setClassName) {
    localStorage.setItem('theme', themeName);
    setClassName(themeName);
}

function keepTheme(setClassName) {
    const theme = localStorage.getItem('theme');
    if (theme) {
      if (theme === 'theme-dark') {
        setTheme('theme-dark',setClassName);
      }
      if (theme === 'theme-light') {
        setTheme('theme-light',setClassName);
      }
      return;
    }
    const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
    if (prefersDarkTheme.matches) {
      setTheme('theme-dark',setClassName);
      return;
    }
    const prefersLightTheme = window.matchMedia('(prefers-color-scheme: light)');
    if (prefersLightTheme.matches) {
      setTheme('theme-light',setClassName);
      return;
    }
    setTheme('theme-light',setClassName);
}

module.exports = {
  setTheme,
  keepTheme
}
