import axios from "axios";
const FormData = require('form-data');

const API_URL = "/api/";

const listFiles = () => {
  return axios.get(API_URL + "upload");
};

const uploadFile = (path,name,file,setPercentage) =>{
    const formData = new FormData();
    console.log(name);
    console.log(name);
    console.log(file);
    formData.append("file", file);
    formData.append("path", path);
    return axios.post(API_URL + "upload", formData, {
        headers: {
        "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function(progressEvent) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(percentCompleted);
            setPercentage(name,percentCompleted);
        },
    });
    
}

const UserService = {
    listFiles,
    uploadFile,
}

export default UserService;
