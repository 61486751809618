import React, { useState } from 'react';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
//import 'react-clock/dist/Clock.css';
import NumericInput from 'react-numeric-input';


const BookingForm = () => {
    const [time, onTimeChange] = useState('10:00');
    const [form, setForm] = useState({
        date: '',
        occasion: '',
        location: '',
        time: '',
        technicalNeeds: [],
        teacherName: '',
        additionalPersons: '',
        email: '',
        laptopUser: '',

    });

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };


    //const handleLocationChange2 = (e) => {
    //    let newSelectionArray;
    //    if (form.location.includes(e.target.value)) {
    //        newSelectionArray = form.location.filter(s => s !== e.target.value)
    //    } else {
    //        newSelectionArray = [...form.location, e.target.value];
    //    }
    //    setForm({ ...form, location: newSelectionArray });
    //};

    const locations = ['große Aula', 'kleine Aula', 'Mensa'];
    const [selectedLocations, setSelectedLocations] = useState([]);

    const handleLocationChange = (event) => {
        const location = event.target.value;
        setSelectedLocations(prevLocations =>
            prevLocations.includes(location)
                ? prevLocations.filter(loc => loc !== location)
                : [...prevLocations, location]
        );
    };


    const handleCheckboxChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.checked });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // handle form submission here
    };

    return (
        <form onSubmit={handleSubmit}>
            <label>
                Wochentag/Datum:
                <input type="date" name="date" onChange={handleChange} />
            </label>
            <label>
                Anlass: <input type="text" name="occasion" onChange={handleChange} />
            </label>
            <label>
                <p>Bitte wählen Sie einen Ort:</p>
                <div style={{ width: 'fit-content', margin: '0.5em', padding: '0 0.5em 0.5em 0.5em', border: '1px solid #ddd', borderRadius: '5px' }}>
                    {locations.map(location => (
                        <div key={location}>
                            <label style={{ display: 'block', color: selectedLocations.includes(location) ? 'darkgray' : 'black', width: 'fit-content' }}>
                                <input
                                    type="checkbox"
                                    style={{ display: 'none' }}
                                    name="location"
                                    value={location}
                                    onChange={handleLocationChange}
                                />
                                {location}
                            </label>
                        </div>
                    ))}
                    <input type="text" name="customLocation" placeholder="Benutzerdefinierter Ort" onChange={handleChange} />
                </div>

            </label>
            <label>
                Uhrzeit:
                <br />
                <TimePicker required={true} shouldOpenClock={() => { return false }} onChange={onTimeChange} value={time} />
                -<TimePicker required={true} shouldOpenClock={() => { return false }} onChange={onTimeChange} value={time} />
                <br />
                <input type="time" name="time" onChange={handleChange} />-
                <input type="time" name="time" onChange={handleChange} />
            </label>
            <label>
                Technischer Bedarf:
                <br></br>
                <input type="checkbox" name="Stehpult u. Standmicro" value="Stehpult u. Standmicro" onChange={handleCheckboxChange} />
                Stehpult u. Standmicro
                <br></br>
                <input type="checkbox" name="Beamer/Laptop/Leinwand" value="Beamer/Laptop/Leinwand" onChange={handleCheckboxChange} />
                Beamer/Laptop/Leinwand
                <br></br>
                <input type="checkbox" name="Handmicro" value="Handmicro" onChange={handleCheckboxChange} />
                Handmicro (mit Anzahl)
                <br></br>
                <input type="checkbox" name="Bestuhlung" value="Bestuhlung" onChange={handleCheckboxChange} />
                Bestuhlung (mit Anzahl)
                <br></br>
                <input type="checkbox" name="Headset" value="Headset" onChange={handleCheckboxChange} />
                Headset <NumericInput min={0} max={100} value={50}/>


                <br></br>
                <input type="checkbox" name="custom" value="custom" onChange={handleCheckboxChange} />
                custom
            </label>
            <label>
                Lehrkraft (Hauptsprechpartner/-in):
                <input type="text" name="teacherName" onChange={handleChange} />
            </label>
            <label>
                ggf. weitere beteiligte Personen:
                <input type="text" name="additionalPersons" onChange={handleChange} />
            </label>
            <label>
                <input type="radio" name="exclusiveOption" value="eigener laptop" onChange={handleChange} />
                Ich möchten einen eigenen Laptop nutzen.
            </label>
            <label>
                <input type="radio" name="exclusiveOption" value="upload" onChange={handleChange} />
                Upload
            </label>
            <button type="submit">Submit</button>
        </form>
    );
};

export default BookingForm;
