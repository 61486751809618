import React, { useState, useRef,useEffect } from 'react';
import './Upload.css';
import FileService from "../services/file.service";

const DnD = ({updates,path}) => {
  const buttonRef = useRef(null)
  const [files, setFiles] = useState(null);
  //const [path, setPath] = useState(path);
  const [dragZone, setDragZone] = useState(false);
  const [remainingFiles, setRemainingFiles] = useState("test"); //number of files
  const clickFilePicker = () => {
    buttonRef.current.click();
  }
  function setPercentageGenerator(setFiles,files){
    return function (name,percentage) {
      console.log(files);
      const newFiles = {...files};
      newFiles[name]["percentage"] = percentage;
      setFiles(newFiles);
    }
  }

  function uploadFiles(files) {
    setRemainingFiles(files.length);
    const resultObj = {};
    Array.from(files).forEach(item => {
      const name = item.name;
      resultObj[name] = {};
      resultObj[name]["file"] = item;
      resultObj[name]["percentage"] = 0;
      FileService.uploadFile(path,name,item,setPercentageGenerator(setFiles,resultObj));
    });
    setFiles(resultObj);
  }

  function handleFiles(event) {
    uploadFiles(event.target.files);
  }
  const dragOverHandler = (event) => {
    event.preventDefault();
    //console.log('File(s) in drop zone');
    //console.log(event);
  }

  const dropHandler = (event) => {
    event.preventDefault();
    setDragZone(false);
    uploadFiles(event.dataTransfer.files);
    //let files = event.dataTransfer.files;
    //setFiles(files);
    //for (let i = 0; i < files.length; i++) {
    //  console.log('File dropped: ', files[i].name);
    //}
  }
  return (
    <>
      <div
        onClick={clickFilePicker}
        id="drop_zone"
        onDrop={(event) => { dropHandler(event) }}
        onDragOver={(event) => { dragOverHandler(event) }}
        onDragEnter={(event) => { setDragZone(true) }}
        onDragLeave={(event) => { setDragZone(false) }}
        style={{
          backgroundColor: dragZone ? "#9b9b9b" : "#fff",
          textAlign: "center", cursor: "pointer",
          margin: "0 auto", border: "1px solid gray",
          borderRadius: "4px",
          userSelect: "none",
          backgroundColor: "var(--input-color)",
          border: "var(--border)",
        }}
      >
        {
          dragZone ? (
            <>Legen Sie Ihre Dateien hier ab</>
          ) : (
            <>Dateien auswählen...</>
          )

        }
        <input ref={buttonRef} type="file" style={{display: "none"}} multiple onChange={(event) => {handleFiles(event)}} />
      </div>
      { files &&
      <>
        <div className='file-item-container'>
          {
            Object.keys(files).map((name) => {
              //console.log(name);
              return (
                <li className="file-item" key={name}>
              <div className="progress" style={{width: files[name]["percentage"]+"%"}}></div>
              {
              //<progress className="progress" max="100" value={files[name]["percentage"]}></progress>
              }
              <div style={{position: "relative", padding: "2px"}}>{name}</div>
              </li>
                );
            })
          }
        </div>
        <div>Verbleibende Dateien: {remainingFiles}</div>
      </>
      }
    </>
  )
}

export default DnD;