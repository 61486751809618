import React, { useEffect,useState } from 'react';

const Container = {
  maxWidth: "600px",
  width: "100%",
  backgroundColor: "var(--light-background)",
  boxShadow: "0px 4px 15px -4px rgba(0,0,0,0.05)",
}

function getWindowDimensions() {
    const { innerWidth: width } = window;
    return width < 600;
  }

const CenteredContainer = ({ children }) => {
    const [isFullWidth, setFullWidth] = useState(getWindowDimensions());
    useEffect(() => {
        function handleResize() {
            setFullWidth(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

  return (
    <div style={
        {...Container,
          border: isFullWidth ? 'none' : '1px solid #EDF1F5',
          borderRadius: isFullWidth ? 'none' : '5px',
          margin: isFullWidth ? 'unset' : '15px auto 15px',
        }}>
           {children} 
      </div>
  );
};

export default CenteredContainer;