import React, { useEffect, useState } from 'react';
import './Toggle.css';
import { setTheme } from '../common/themes';
import { ReactComponent as MoonSvg } from '../moon.svg';
import { ReactComponent as SunSvg } from '../sun.svg';

function Toggle({setClassName}) {
    const [togClass, setTogClass] = useState('dark');
    let theme = localStorage.getItem('theme');

    const handleOnClick = () => {
        if (localStorage.getItem('theme') === 'theme-dark') {
            setTheme('theme-light',setClassName);
            setTogClass('light')
        } else {
            setTheme('theme-dark',setClassName);
            setTogClass('dark')
        }
    }

    useEffect(() => {
        if (localStorage.getItem('theme') === 'theme-dark') {
            setTogClass('dark')
        } else if (localStorage.getItem('theme') === 'theme-light') {
            setTogClass('light')
        }
    }, [theme])

    return (
        <div className="ml-auto toggle-container">
            {
                togClass === "light" ?
                <input type="checkbox" id="toggle" className="toggle--checkbox" onClick={handleOnClick} checked />
                :
                <input type="checkbox" id="toggle" className="toggle--checkbox" onClick={handleOnClick} />
            }
            <label htmlFor="toggle" className="toggle--label">
                <span className="toggle--label-background">
            {
                togClass === "light" ?
                <SunSvg style={{display:"inline-block",right:"4px",position:"absolute",top:"4px",width:"22px"}}/>
                :
                <MoonSvg style={{display:"inline-block",left:"3px",position:"absolute",top:"3px",width:"24px"}}/>
            }

                </span>
            </label>
        </div>
    )
}

export default Toggle;